import * as Fingerprint2 from 'fingerprintjs2'
import * as UAParser from 'ua-parser-js'
import axios from 'axios'
import { ErrorWrapper, ResponseWrapper } from './utils'
import { API_URL } from '../env'
import { Http } from '@/services/http.init'

var Vue = require('vue')

let BEARER = ''

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */

  static request (status = { auth: true }) {
    return new Http(status)
  }

  static async makeLogin ({ username, password }) {
    const fingerprint = await _getFingerprint()

    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/auth/login/`, { username, password, fingerprint })
        .then(response => {
          _setAuthData(response.data.auth_token, response.data.username, response.data.is_team_admin)
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => reject(new ErrorWrapper(error)))
    })
  }

  static async makeRegister (_userData) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/auth/users/`, _userData)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => reject(new ErrorWrapper(error)))
    })
  }

  // static async makeRegister (_userData) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`${API_URL}/auth/users/`, _userData)
  //       .then(response => {
  //         return resolve(new ResponseWrapper(response, response.data, ''))
  //       }).catch(error => reject(new ErrorWrapper(error)))
  //   })
  // }

  static makeLogout () {
    _resetAuthData()
  }

  static async sendPasswordResetEmail (_userData) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/auth/password-reset-email/`, _userData)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => reject(new ErrorWrapper(error)))
    })
  }

  static async passwordResetConfirm (_userData) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/auth/password-reset-confirm/`, _userData)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => reject(new ErrorWrapper(error)))
    })
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static getAuthToken () {
    return Vue.default.cookie.get('token')
  }

  static setTeamAdmin (isTeamAdmin) {
    Vue.default.cookie.set('is_team_admin', isTeamAdmin, { expires: 999 })
  }

  static setAuthToken (refreshToken) {
    Vue.default.cookie.set('token', refreshToken, { expires: 999 })
  }

  static getBearer () {
    return BEARER
  }

  static setBearer (accessToken) {
    BEARER = `Bearer ${accessToken}`
  }

  static resetPasswordAndSendMail (data) {
    // data = {'userId':61}
    const url = `${API_URL}/auth/reset-password-and-send-mail/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => reject(new ErrorWrapper(error)))
    })
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _resetAuthData () {
  Vue.default.cookie.set('token', '', { expires: 0 })
  Vue.default.cookie.set('username', '')
}

function _setAuthData (token, username, isTeamAdmin) {
  AuthService.setAuthToken(token)
  Vue.default.cookie.set('username', username, { expires: 999 })
  Vue.default.cookie.set('is_team_admin', isTeamAdmin, { expires: 999 })
}

function _getFingerprint () {
  return new Promise((resolve, reject) => {
    async function getHash () {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value)
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
            }
            return value
          }
        }
      }

      try {
        const components = await Fingerprint2.getPromise(options)
        const values = components.map(component => component.value)
        console.log('fingerprint hash components', components)

        return String(Fingerprint2.x64hash128(values.join(''), 31))
      } catch (e) {
        reject(e)
      }
    }

    if (window.requestIdleCallback) {
      console.log('get fp hash @ requestIdleCallback')
      requestIdleCallback(async () => resolve(await getHash()))
    } else {
      console.log('get fp hash @ setTimeout')
      setTimeout(async () => resolve(await getHash()), 500)
    }
  })
}
