import { BaseService } from './base.service'
import { Http } from './http.init'
import { API_URL } from '../env'
import axios from 'axios'
import { ErrorWrapper, ResponseWrapper } from './utils'

var Vue = require('vue')

export class ProfileDictionaryEntryService extends BaseService {
  static get entity () {
    return 'profile_dictionary_entries'
  }

  static request (status = { auth: true }) {
    return new Http(status)
  }

  static getProfileDictionaryEntryStatistics (username) {
    const url = `${API_URL}/dictionary_entry/profile_dictionary_entries/?username=${username}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static updatePlaceholder (username, newPlaceholder) {
    const url = `${API_URL}/auth/shortform-placeholder/${newPlaceholder}/?username=${username}`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = {}
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }
}
