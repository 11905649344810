import qs from 'qs'
import { Http } from './http.init'
import { ErrorWrapper, ResponseWrapper } from './utils'
import axios from 'axios'
import { API_URL } from '../env'

var Vue = require('vue')

export class BaseService {
  static get entity () {
    throw new Error('"entity" getter not defined')
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request (status = { auth: false }) {
    return new Http(status)
  }

  static responseWrapper (...rest) {
    return new ResponseWrapper(...rest)
  }

  static errorWrapper (...rest) {
    return new ErrorWrapper(...rest)
  }

  static querystring (obj) {
    return qs.stringify(obj, {
      encode: false
    })
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static getListPublic (parameters = {}) {
    const params = {
      ...parameters
    }
    return new Promise((resolve, reject) => {
      return this.request().get(`${this.entity}`, { params })
        .then(response => {
          const data = {
            content: response.data.data.results,
            total: response.data.data.total
          }
          resolve(this.responseWrapper(response, data))
        }).catch(error => {
          const message = error.response.data ? error.response.data.error : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  static getByIdPublic (id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request().get(`${this.entity}/${id}`)
        .then(response => resolve(this.responseWrapper(response, response.data.data)))
        .catch(error => {
          const message = error.response.data ? error.response.data.error : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static like (id) {
    const url = `${API_URL}/dictionary_entry/like/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, {}, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static dislike (id) {
    const url = `${API_URL}/dictionary_entry/dislike/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, {}, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static flag (id) {
    const url = `${API_URL}/dictionary_entry/flag/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, {}, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getById (id) {
    const url = `${API_URL}/api/${this.entity}/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getDictionaryEntryById (id) {
    const url = `${API_URL}/api/dictionary_entry_list/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getPaginatedData (apiUrl) {
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = ''
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(apiUrl, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getListViewData (_searchKeyword, filterBy, ordering) {
    let url = ''
    if (_searchKeyword) {
      url = `${API_URL}/api/${this.entity}/?search=${_searchKeyword}&user=${filterBy}&order=${ordering}`
    } else {
      url = `${API_URL}/api/${this.entity}/?user=${filterBy}&order=${ordering}`
    }
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = ''
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static create (data) {
    const url = `${API_URL}/api/${this.entity}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.post(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static update (data, id) {
    const url = `${API_URL}/api/${this.entity}/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static remove (data, id) {
    const url = `${API_URL}/api/${this.entity}/${id}/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.put(url, data, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }
}
