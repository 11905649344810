<template>
  <div>
  <b-navbar toggleable="xl" type="dark" variant="primary" fixed="top"
            v-on-clickaway="hideNavBar">
    <b-navbar-brand href="/">

      <b-img :src="logoPath" fluid alt="gender app logo" width="150%"></b-img>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"><span class= "gold p-1 pr-3 align-middle" v-if="isLoggedIn() && this.total_likes>-1">{{total_likes-total_dislikes}}</span><span class="navbar-toggler-icon"></span></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav  v-model="isNavbarCollapseOpen" >
      <b-navbar-nav>
        <b-nav-item  href="/">{{$t("home")}}</b-nav-item>
        <b-nav-item :to="{'name': 'add-in'}">{{$t("Add-In")}}</b-nav-item>
        <b-nav-item :to="{'name': 'lektorat.website-check'}">{{$t("Website Check")}}</b-nav-item>
        <b-nav-item :to="{'name': 'about'}">{{$t("About gender app")}}</b-nav-item>
        <b-nav-item-dropdown>
          <template v-slot:button-content>
            {{$t('more')}}
          </template>
          <b-dropdown-item>
            <router-link  :to="{'name': 'language'}">{{$t("Language")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'commercial'}">{{$t("Commercial Use")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'support'}">{{$t("Support")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'team'}">{{$t("Team")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'lektorat.solution'}">{{$t("Lektorat")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'manual'}">{{$t("Manual")}}</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{'name': 'inprint'}">{{$t("Inprint")}}</router-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-show="isLoggedIn()" :to="{name:'dictionary.entry.add'}" class="btn-success">{{$t('Add Entry')}}
        </b-nav-item>
        <b-nav-item v-show="!isLoggedIn()" @click="showRegistrationModal" class="btn-success">{{$t('Add Entry')}}
        </b-nav-item>
        <b-nav-item :to="{name:'auth.login'}" v-if="!isLoggedIn()">{{$t('Login')}}</b-nav-item>
        <b-nav-item :to="{name:'auth.register'}" v-if="!isLoggedIn()">{{$t('Register')}}</b-nav-item>
        <b-nav-item :to="{name:'auth.profile', params:{username: getUsername()}}" class="small gold p-1 pr-2 pl-2 align-middle text-center gold d-none d-xl-block" v-if="isLoggedIn() && this.total_likes>-1">{{total_likes-total_dislikes}}</b-nav-item>
        <b-nav-item class="small gold p-1 pr-2 pl-2 align-middle text-center gold d-none d-xl-block" v-if="isLoggedIn() && this.total_likes===-1"></b-nav-item>
        <b-nav-item-dropdown right v-if="isLoggedIn()">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            {{getTruncateUsername(getUsername(),15)}}
          </template>
          <b-dropdown-item>
            <router-link :to="{name:'auth.profile', params:{username: getUsername()}}">
              {{$t("Profile")}}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item v-show="isTeamAdmin()">
            <router-link :to="{name:'team.team-dashboard'}">
              {{$t("team-dashboard")}}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="makeLogout">{{$t("Sign Out")}}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>

    <div>
      <b-modal hide-footer ref="registerModal" v-bind:title="$t('you are not logged in')">
        <div class="d-block text-center">
          <h3>{{$t('wanna create an account?')}}</h3>
        </div>
        <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}
        </router-link>
        <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}</router-link>
        <b-button block @click="hideRegistrationModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}
        </b-button>
      </b-modal>
    </div>
    </div>
</template>

<script>
import { AuthService } from '../services/auth.service'
import { ProfileDictionaryEntryService } from '../services/profileDictionaryEntryService'

var Vue = require('vue')
export default {
  name: 'TopNavBar',
  data () {
    return {
      logoPath: '/img/logo.png',
      total_likes: -1,
      total_dislikes: -1,
      error: '',
      isNavbarCollapseOpen: false
    }
  },
  props: ['secondTierLoadingDone'],
  methods: {
    hideNavBar () {
      this.isNavbarCollapseOpen = false
    },
    makeLogout () {
      AuthService.makeLogout()
      window.location.reload()
    },
    getUsername () {
      return Vue.default.cookie.get('username', 'Profil')
    },
    isLoggedIn () {
      const token = Vue.default.cookie.get('token')
      const username = Vue.default.cookie.get('username')
      return !!token && !!username
    },
    isTeamAdmin () {
      const isAdminUser = Vue.default.cookie.get('is_team_admin')
      const isAdminUserLowercase = isAdminUser ? isAdminUser.toLowerCase() : 'false'
      return isAdminUserLowercase === 'true'
    },
    showRegistrationModal () {
      this.$refs.registerModal.show()
    },
    hideRegistrationModal () {
      this.$refs.registerModal.hide()
    },
    getTruncateUsername (username, length, clamp) {
      clamp = clamp || '...'
      var node = document.createElement('div')
      node.innerHTML = username
      var content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },
    async getUserTermStatistics () {
      await this.siteLoaded()
      await ProfileDictionaryEntryService.getProfileDictionaryEntryStatistics(this.getUsername())
        .then((response) => {
          // console.log(response)
          this.total_likes = response.data.total_likes
          this.total_dislikes = response.data.total_dislikes
        }).catch((error) => {
          console.log(error)
        })
    },

    siteLoaded: async function () {
      while (!this.secondTierLoadingDone) {
        await wait(100)
      }
      return Promise.resolve('ACK')
    }
  },

  mounted () {
    this.getUserTermStatistics()
  }
}

async function wait (ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
</script>

<style scoped>

</style>
