/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'

import { API_URL } from '../env'
import { AuthService } from './auth.service'

export class Http {
  constructor (status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: API_URL
    })

    return this.init()
  }

  init () {
    this.instance.request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + AuthService.getAuthToken()
      }
    }
    return this.instance
  }
}
